.input-container {
  //padding-bottom: 8px;
  position: relative;

  input,
  select {
    position: relative;
    height: 40px;
    color: #282c3f;
    border: 1px solid #dbd6d6;
    //font-weight: 600;
  }

  i {
    // position: absolute;
    // top: 40px;
    right: 10px;
    left: auto;
    color: $color-primary-100;
    cursor: pointer;
    margin-left: 8px;
  }
}

.input.name {
  padding-right: 55px;
  background-image: url(../../../images/input-user.svg);
  background-position: 93% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.input.email {
  padding-right: 55px;
  background-image: url(../../../images/input-massage.svg);
  background-position: 96% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.input.password {
  padding-right: 55px;
  background-image: url(../../../images/input-password.svg);
  background-position: 96% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: 20px;
}

.input.phone {
  padding-right: 55px;
  background-image: url(../../../images/input-number.svg);
  background-position: 93% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.input.company {
  padding-right: 55px;
  background-image: url(../../../images/input-buldding.svg);
  background-position: 93% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.input.w-input:focus,
.input.w-input:focus-visible {
  border-color: $color-primary !important;
  box-shadow: 0 2px 11px 0 rgba(244, 38, 62, 0.06),
    0 4px 10px 0 rgba(31, 37, 89, 0.07);
  color: $color-primary;
  outline: none !important;
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  background-color: $color-white;
}

.input {
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: $color-neutral-300;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(19, 18, 66, 0.07);
  transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease;
  color: $color-primary;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;

  &.login-text_box {
    height: 46px;
  }
}

.w-input[disabled]:not(.w-input-disabled),
.w-select[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
  background-color: #eeeeee;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
}

.w-select[multiple] {
  height: auto;
}

.label-padding {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.input-container_main {
  display: flex;
  align-items: center;
}

.input-container_label {
  padding-right: 17px;
  width: 40%;
  text-align: start;
}

.input-container_input {
  display: block;
  width: 40%;
  padding: 8px 12px;
  background-color: #fff;
  color: #ee4437;
}

.input-container_input:focus,
.input-container_input:focus-visible {
  border-color: #ee4437 !important;
  box-shadow: 0 2px 11px 0 rgba(244, 38, 62, 0.06),
    0 4px 10px 0 rgba(31, 37, 89, 0.07);
  color: #ee4437;
  outline: none !important;
}

@media (max-width: 991px) {
  .input-container_main {
    display: block;
  }

  .input-container_label {
    padding-bottom: 0.5rem;
    width: 100%;
    text-align: start;
  }

  .input-container_dis {
    width: 100% !important;
    padding-left: 1rem;
  }

  .input_emp_label {
    display: none;
  }

  .input-container_input {
    display: block;
    width: 100%;
    padding: 8px 12px;
    background-color: #fff;
  }

  .input-container_select {
    //padding: 15px 0px;
    position: relative;
    width: 100% !important;
  }
}

.input-container_select {
  // padding: 15px 0px;
  position: relative;
  width: 40%;

  input {
    position: absolute;
  }
}

.textare-container_select {
  width: 100%;
}

.input-container_lable {
  display: block;
  width: 60%;
  padding: 8px 12px;
  background-color: #fff;
}

.input-container_dis {
  width: 60%;
  padding-left: 1rem;
  padding-top: 8px;
}

.hr-line {
  color: #6e727c;
  margin: 1.2rem 0;
}

.react-select__control {
  color: $color-neutral-800;
  border: 1px solid #dbd6d6;
  //font-weight: 600;
  background-color: #fff;
  padding: 2px !important;
  border-radius: 4px !important;
  // box-shadow: 0 2px 6px 0 rgba(19, 18, 66, 0.07) !important;
  transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease !important;

  &-focused {
    border-color: $color-neutral-800 !important;
    box-shadow: 0 2px 11px 0 rgba(244, 38, 62, 0.06),
      0 4px 10px 0 rgba(31, 37, 89, 0.07);
    color: $color-neutral-800;
    outline: none !important;
    //font-weight: 600;
  }
}

.react-select__multiValue {
  background-color: #ee4437 !important;
  color: #fff !important;
  border-radius: 8px !important;
}
